import {
    ABSENCE_REQUEST_KINDS_CODENAMES,
    TRIP_CODENAMES,
    DISABILITY_CODENAMES,
    PARENTAL_LEAVE_CODENAMES,
    SICKNESS_FAMILY_CODENAMES,
} from '@jetCommon/constants/absence-request-constants';

const TARGET_LABEL_FOR_ABSENCE_KIND = {
    ALL: 'a tutte le persone',
    INTERNAL: 'a dipendenti e apprendisti',
    EXTERNAL: 'a collaboratori e tirocinanti',
};

const _mapGroupToTarget = (group, target) => group.reduce((acc, codename) => ({...acc, [codename]: target}), {});

const TARGET_FOR_ABSENCE_KIND = {
    [ABSENCE_REQUEST_KINDS_CODENAMES.OVERTIME]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.REMOTE]: TARGET_LABEL_FOR_ABSENCE_KIND.ALL,
    ..._mapGroupToTarget(TRIP_CODENAMES, TARGET_LABEL_FOR_ABSENCE_KIND.ALL),
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOLIDAY]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.HOURLY_LEAVE]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.STUDY]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.DONOR]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.FEEDING]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC_NON_EMPLOYEE]: TARGET_LABEL_FOR_ABSENCE_KIND.EXTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.MARRIAGE]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    ..._mapGroupToTarget(PARENTAL_LEAVE_CODENAMES, TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL),
    ..._mapGroupToTarget(SICKNESS_FAMILY_CODENAMES, TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL),
    ..._mapGroupToTarget(DISABILITY_CODENAMES, TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL),
    [ABSENCE_REQUEST_KINDS_CODENAMES.MOURNING]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
    [ABSENCE_REQUEST_KINDS_CODENAMES.INJURY]: TARGET_LABEL_FOR_ABSENCE_KIND.INTERNAL,
};

const ABSENCE_KIND_GROUPS = {
    PRESENCE: 'PRESENCE',
    HOLIDAYS_AND_PERMITS: 'HOLIDAYS_AND_PERMITS',
    LEAVE: 'LEAVE',
};
const ABSENCE_KIND_GROUP_LABEL = {
    [ABSENCE_KIND_GROUPS.PRESENCE]: 'Presenze',
    [ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS]: 'Ferie e permessi',
    [ABSENCE_KIND_GROUPS.LEAVE]: 'Congedi',
};

const ABSENCE_KINDS_GROUPED = {
    [ABSENCE_KIND_GROUPS.PRESENCE]: [
        ABSENCE_REQUEST_KINDS_CODENAMES.OVERTIME,
        ABSENCE_REQUEST_KINDS_CODENAMES.REMOTE,
        ...TRIP_CODENAMES,
    ],
    [ABSENCE_KIND_GROUPS.HOLIDAYS_AND_PERMITS]: [
        ABSENCE_REQUEST_KINDS_CODENAMES.HOLIDAY,
        ABSENCE_REQUEST_KINDS_CODENAMES.HOURLY_LEAVE,
        ABSENCE_REQUEST_KINDS_CODENAMES.STUDY,
        ABSENCE_REQUEST_KINDS_CODENAMES.DONOR,
        ABSENCE_REQUEST_KINDS_CODENAMES.FEEDING,
        ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC,
        ABSENCE_REQUEST_KINDS_CODENAMES.GENERIC_NON_EMPLOYEE,
    ],
    [ABSENCE_KIND_GROUPS.LEAVE]: [
        ABSENCE_REQUEST_KINDS_CODENAMES.SICKNESS,
        ABSENCE_REQUEST_KINDS_CODENAMES.MARRIAGE,
        ...PARENTAL_LEAVE_CODENAMES,
        ...SICKNESS_FAMILY_CODENAMES,
        ...DISABILITY_CODENAMES,
        ABSENCE_REQUEST_KINDS_CODENAMES.MOURNING,
        ABSENCE_REQUEST_KINDS_CODENAMES.INJURY,
    ],
};

export {
    TARGET_FOR_ABSENCE_KIND,
    TARGET_LABEL_FOR_ABSENCE_KIND,
    ABSENCE_KIND_GROUPS,
    ABSENCE_KIND_GROUP_LABEL,
    ABSENCE_KINDS_GROUPED,
};
