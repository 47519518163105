/*
 * This JS module can be used to configure a new type of policy.
 * Once these constants are defined, the custom settings module
 * must be defined in company-policy/{policy-name}/PolicySettings{PolicyName}.vue
 * and added injected in company-policy/views/CompanyPolicyView.vue
 */
import {CONTRACT_STATUS} from '@jetCommon/constants/employee-constants.js';

import ClockingPolicySettings from './clocking/components/ClockingPolicySettings.vue';
import GeneralClockingSettings from './clocking/components/GeneralClockingSettings.vue';
import PolicySettingsAbsence from './absence/components/PolicySettingsAbsence.vue';
import PolicySettingsExpense from '@/features/company-policy/expense/components/PolicySettingsExpense.vue';
import PolicySettingsHeadquarters from '@/features/company-policy/headquarters/components/PolicySettingsHeadquarters.vue';
import PolicySettingsTripDailyRefund from '@/features/company-policy/trip-daily-refund/components/PolicySettingsTripDailyRefund.vue';
import TripDailyRefundHowItWorksModal from '@/features/company-policy/trip-daily-refund/components/TripDailyRefundHowItWorksModal.vue';

export const COMPANY_POLICY_CATEGORY = {
    ABSENCE: 'ABSENCE',
    EXPENSE: 'EXPENSE',
    MEAL_VOUCHER: 'MEAL_VOUCHER',
    HEADQUARTERS: 'HEADQUARTERS',
    CLOCKING: 'CLOCKING',
    TRIP_DAILY_REFUND: 'TRIP_DAILY_REFUND',
    // ABSENCE_CALENDAR_ENABLED al momento non è una company policy
    // ma deve diventarlo presto. Nel frattempo la aggiungiamo qui per poter mantenere
    // i messaggi di disabilitazione funzionalità insieme agli altri.
    ABSENCE_CALENDAR_ENABLED: 'ABSENCE_CALENDAR_ENABLED',
};

/**
 * @typedef {Object} CompanyPolicyConfig
 * @property {string} routerKey - The router key for the policy
 * @property {string|null} companySettingsIsEnabledField - The company settings field that enables the policy.
 * Set it to NULL if the policy is always enabled and cannot be disabled
 * @property {string} title - The title of the policy
 * @property {string} groupLabel - The label of the policy group
 * @property {string|null} sectionRoute - The route to for a dedicated policy section, if any.
 * Set it to NULL to hide the "Vai alla sezione" link.
 * @property {string} policyAPI - The class name to handle API query
 * @property {boolean} canAdminSetEnabledStatus - Whether the admin can set the enabled status
 * @property {object} generalSettingsComponent - This component should be used to contain
 *  settings unrelated to a specific policy. It is mounted above the policySettingsComponent
 *  and can be freely customized.
 * @property {object} policySettingsComponent - The form to set policy properties, as SFC.
 *  This component accepts a prop `policy` which is the policy object
 *  and emits an event @updated-form which should be triggered when the
 *  policy form is updated.
 * @property {object|null} howItWorksModalComponent - The modal to show the "How it works" section
 * @property {object} employeeQueryParams - Query params to filter employees (e.g. by contract status)
 * @property {string|null} assigneeTableInfoCalloutContent - The callout text for the assignee table page
 * @property {string|null} addPolicyButtonLabel - The custom label for "Aggiungi policy" button
 * @property {string|null} assignPolicyColumnLabel - The custom label for the "Assegna policy" column
 */

/**
 * @readonly
 * @enum {CompanyPolicyConfig}
 */
export const COMPANY_POLICY_CONFIGS = {
    [COMPANY_POLICY_CATEGORY.ABSENCE]: {
        routerKey: 'assenze',
        companySettingsIsEnabledField: 'absences_enabled',
        title: 'Presenze e assenze',
        groupLabel: 'Policy presenze e assenze',
        sectionRoute: 'absenceRequestsView',
        policyAPI: 'companyAbsencePolicies',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        policySettingsComponent: PolicySettingsAbsence,
        assigneeTableInfoCalloutContent:
            'Le persone con contratto di collaborazione ed i tirocinanti vedranno solo le tipologie di presenza e assenza a loro applicabili, se le hai attivate. Non avranno invece accesso alle tipologie applicabili solo ai dipendenti, anche se attivate da policy.',
        deactivateDialogMessages: {
            title: 'Disattivare presenze e assenze?',
            warningMessage:
                'Stai per disattivare presenze/assenze: i dipendenti non potranno più inserire nuove richieste e la sezione non sarà più visibile per nessun admin. Eventuali richieste approvate in precedenza continueranno a essere valide.',
            ctaMessage: 'Confermi di voler disattivare presenze e assenze?',
        },
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
        },
        invalidateQueryKeysOnDelete: ['getAbsencePolicyConfiguration'],
    },
    [COMPANY_POLICY_CATEGORY.EXPENSE]: {
        routerKey: 'rimborsi-spese',
        companySettingsIsEnabledField: 'expenses_enabled',
        title: 'Rimborsi spese',
        groupLabel: 'Policy rimborsi spese',
        sectionRoute: 'expensesView',
        policyAPI: 'companyExpensePolicy',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        policySettingsComponent: PolicySettingsExpense,
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
        },
        deactivateDialogMessages: {
            title: 'Disattivare i rimborsi spese?',
            warningMessage:
                'Stai per disattivare i rimborsi spese. I dipendenti non potranno più inserire nuove richieste di rimborso e la sezione non sarà più visibile a nessun admin. Eventuali richieste precedentemente approvate continueranno a essere valide.',
            ctaMessage: 'Confermi di voler disattivare i rimborsi spese?',
        },
    },
    [COMPANY_POLICY_CATEGORY.MEAL_VOUCHER]: {
        // TODO: refactor meal voucher to use new company policy configuration
        companySettingsIsEnabledField: 'meal_vouchers_enabled',
        title: 'Buoni pasto',
        policyAPI: 'companyMealVoucherPolicies',
        deactivateDialogMessages: {
            title: 'Disattivare i buoni pasto?',
            warningMessage:
                'Stai per disattivare i buoni pasto. La sezione non sarà più visibile per nessun admin e a partire dal mese corrente i buoni pasto non saranno più conteggiati e inseriti in cedolino.',
            ctaMessage: 'Confermi di voler disattivare i buoni pasto?',
        },
    },
    [COMPANY_POLICY_CATEGORY.CLOCKING]: {
        routerKey: 'timbrature',
        companySettingsIsEnabledField: 'clockings_configurable',
        title: 'Timbrature',
        groupLabel: 'Policy timbrature',
        // TODO: to be defined once we develop the feature
        sectionRoute: '',
        policyAPI: 'companyClockingPolicy',
        canAdminSetEnabledStatus: false,
        generalSettingsComponent: GeneralClockingSettings,
        policySettingsComponent: ClockingPolicySettings,
        assigneeTableInfoCalloutContent:
            'Puoi assegnare una policy di timbratura solamente alle persone con orario fisso.',
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
            working_hours_kind__in: 'FIXED,SHIFT',
        },
    },
    [COMPANY_POLICY_CATEGORY.TRIP_DAILY_REFUND]: {
        routerKey: 'diaria-trasferta',
        companySettingsIsEnabledField: 'trip_daily_refund_enabled',
        title: 'Diaria per trasferta',
        groupLabel: 'Policy diaria per trasferta',
        policyAPI: 'companyTripDailyRefundPolicy',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        policySettingsComponent: PolicySettingsTripDailyRefund,
        howItWorksModalComponent: TripDailyRefundHowItWorksModal,
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
        },
        deactivateDialogMessages: {
            title: 'Disattivare le diarie per trasferta?',
            warningMessage:
                'Stai per disattivare l’assegnazione automatica di diarie per le trasferte. Proseguendo, verranno rimosse dalla sezione Extra le diarie inserite automaticamente a partire dal mese corrente e non ne saranno più create di nuove.',
            ctaMessage: 'Confermi di voler disattivare le diarie per trasferta?',
        },
    },
    [COMPANY_POLICY_CATEGORY.HEADQUARTERS]: {
        routerKey: 'sedi-aziendali',
        companySettingsIsEnabledField: null,
        title: 'Sedi aziendali',
        groupLabel: 'Elenco delle sedi',
        sectionRoute: null,
        policyAPI: 'companyHeadquartersPolicy',
        canAdminSetEnabledStatus: false,
        policySettingsComponent: PolicySettingsHeadquarters,
        assigneeTableInfoCalloutContent: 'I dipendenti sprovvisti di sede verranno assegnati alla Sede Legale.',
        addPolicyButtonLabel: 'Aggiungi sede',
        assignPolicyColumnLabel: 'Assegna sede',
    },
    [COMPANY_POLICY_CATEGORY.ABSENCE_CALENDAR_ENABLED]: {
        companySettingsIsEnabledField: 'absence_calendar_enabled',
        deactivateDialogMessages: {
            title: 'Disattivare il calendario condiviso?',
            warningMessage:
                'Stai per disattivare il calendario condiviso. La sezione non sarà più visibile per tutti gli admin e i dipendenti.',
            ctaMessage: 'Confermi di voler disattivare il calendario condiviso?',
        },
    },
};

export const ROUTER_KEYS = Object.keys(COMPANY_POLICY_CONFIGS).map(key => COMPANY_POLICY_CONFIGS[key].routerKey);
